// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-b-2-b-assessment-js": () => import("./../../../src/pages/b2b/assessment.js" /* webpackChunkName: "component---src-pages-b-2-b-assessment-js" */),
  "component---src-pages-b-2-b-js": () => import("./../../../src/pages/b2b.js" /* webpackChunkName: "component---src-pages-b-2-b-js" */),
  "component---src-pages-ces-2020-js": () => import("./../../../src/pages/ces-2020.js" /* webpackChunkName: "component---src-pages-ces-2020-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid-19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-events-product-support-summit-tsx": () => import("./../../../src/pages/events/product-support-summit.tsx" /* webpackChunkName: "component---src-pages-events-product-support-summit-tsx" */),
  "component---src-pages-features-video-js": () => import("./../../../src/pages/features/video.js" /* webpackChunkName: "component---src-pages-features-video-js" */),
  "component---src-pages-impact-analysis-js": () => import("./../../../src/pages/impact-analysis.js" /* webpackChunkName: "component---src-pages-impact-analysis-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-product-live-support-js": () => import("./../../../src/pages/product/live-support.js" /* webpackChunkName: "component---src-pages-product-live-support-js" */),
  "component---src-pages-product-self-service-js": () => import("./../../../src/pages/product/self-service.js" /* webpackChunkName: "component---src-pages-product-self-service-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-roi-details-js": () => import("./../../../src/pages/roi/details.js" /* webpackChunkName: "component---src-pages-roi-details-js" */),
  "component---src-pages-roi-inputs-js": () => import("./../../../src/pages/roi/inputs.js" /* webpackChunkName: "component---src-pages-roi-inputs-js" */),
  "component---src-pages-roi-js": () => import("./../../../src/pages/roi.js" /* webpackChunkName: "component---src-pages-roi-js" */),
  "component---src-pages-roi-new-details-js": () => import("./../../../src/pages/roi-new/details.js" /* webpackChunkName: "component---src-pages-roi-new-details-js" */),
  "component---src-pages-roi-new-inputs-tsx": () => import("./../../../src/pages/roi-new/inputs.tsx" /* webpackChunkName: "component---src-pages-roi-new-inputs-tsx" */),
  "component---src-pages-roi-new-results-js": () => import("./../../../src/pages/roi-new/results.js" /* webpackChunkName: "component---src-pages-roi-new-results-js" */),
  "component---src-pages-roi-new-tsx": () => import("./../../../src/pages/roi-new.tsx" /* webpackChunkName: "component---src-pages-roi-new-tsx" */),
  "component---src-pages-roi-results-js": () => import("./../../../src/pages/roi/results.js" /* webpackChunkName: "component---src-pages-roi-results-js" */),
  "component---src-pages-testdrive-js": () => import("./../../../src/pages/testdrive.js" /* webpackChunkName: "component---src-pages-testdrive-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-pages-why-mavenoid-js": () => import("./../../../src/pages/why-mavenoid.js" /* webpackChunkName: "component---src-pages-why-mavenoid-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-careers-tsx": () => import("./../../../src/templates/careers.tsx" /* webpackChunkName: "component---src-templates-careers-tsx" */),
  "component---src-templates-customers-tsx": () => import("./../../../src/templates/customers.tsx" /* webpackChunkName: "component---src-templates-customers-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-personalized-customer-landing-page-js": () => import("./../../../src/templates/personalizedCustomerLandingPage.js" /* webpackChunkName: "component---src-templates-personalized-customer-landing-page-js" */),
  "component---src-templates-success-story-tsx": () => import("./../../../src/templates/successStory.tsx" /* webpackChunkName: "component---src-templates-success-story-tsx" */),
  "component---src-templates-testdrive-page-tsx": () => import("./../../../src/templates/testdrivePage.tsx" /* webpackChunkName: "component---src-templates-testdrive-page-tsx" */)
}

